<template>
  <b-container>
    <h1>Sign in</h1>
    
    <p>Please wait while you are signed in...</p>
  </b-container>
</template>

<script>
export default {
  name: 'SignIn',
  mounted() {
    debugger;
  }
};
</script>

<style scoped>

</style>
